import React, { useEffect } from 'react';
import styled from 'styled-components';
import './transparentHeader.scss';
import { StaticImage } from "gatsby-plugin-image"


const LogoContainer = styled.div`
    width: 17%;

    @media (max-width: 769px) {
        width: 30%;
    }
    @media (max-width: 500px) {
        width: 40%;
    }
`;

const TransparentHeaderTemplate = () => {
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, []);


    function handleScroll() {
        if (window.scrollY > 20) {
            document.querySelector(".container").className = "container scroll";

        } else {
            document.querySelector(".container").className = "container";
        }
    };

    return (
        <div className="desktop-menu">
            <div className="container">
                <div className="header-container">
                    <LogoContainer href="https://greenwavefinance.com/" target="_blank">
                        <StaticImage src="../../../resources/images/logos/Greenwave-Finance-Logo.png" alt="Greenwave Logo"  placeholder="none" />
                    </LogoContainer>

                    <a className="phone-number" href="tel:1234567890">
                    (123) 456-7890
                    </a>
                </div>
            </div>
        </div>
    );
}

export default TransparentHeaderTemplate;

